// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-intro-index-jsx": () => import("./../../../src/pages/company-intro/index.jsx" /* webpackChunkName: "component---src-pages-company-intro-index-jsx" */),
  "component---src-pages-core-technology-depth-video-analysis-jsx": () => import("./../../../src/pages/core-technology/depth-video-analysis.jsx" /* webpackChunkName: "component---src-pages-core-technology-depth-video-analysis-jsx" */),
  "component---src-pages-core-technology-height-video-analysis-jsx": () => import("./../../../src/pages/core-technology/height-video-analysis.jsx" /* webpackChunkName: "component---src-pages-core-technology-height-video-analysis-jsx" */),
  "component---src-pages-core-technology-intelligent-hardware-jsx": () => import("./../../../src/pages/core-technology/intelligent-hardware.jsx" /* webpackChunkName: "component---src-pages-core-technology-intelligent-hardware-jsx" */),
  "component---src-pages-core-technology-plant-insect-pests-analysis-jsx": () => import("./../../../src/pages/core-technology/plant-insect-pests-analysis.jsx" /* webpackChunkName: "component---src-pages-core-technology-plant-insect-pests-analysis-jsx" */),
  "component---src-pages-core-technology-texture-recognition-jsx": () => import("./../../../src/pages/core-technology/texture-recognition.jsx" /* webpackChunkName: "component---src-pages-core-technology-texture-recognition-jsx" */),
  "component---src-pages-core-technology-trace-source-jsx": () => import("./../../../src/pages/core-technology/trace-source.jsx" /* webpackChunkName: "component---src-pages-core-technology-trace-source-jsx" */),
  "component---src-pages-honor-index-jsx": () => import("./../../../src/pages/honor/index.jsx" /* webpackChunkName: "component---src-pages-honor-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-product-digitizing-jsx": () => import("./../../../src/pages/product/digitizing.jsx" /* webpackChunkName: "component---src-pages-product-digitizing-jsx" */),
  "component---src-pages-product-hardware-jsx": () => import("./../../../src/pages/product/hardware.jsx" /* webpackChunkName: "component---src-pages-product-hardware-jsx" */),
  "component---src-pages-product-online-transaction-jsx": () => import("./../../../src/pages/product/online-transaction.jsx" /* webpackChunkName: "component---src-pages-product-online-transaction-jsx" */),
  "component---src-pages-product-supply-chain-jsx": () => import("./../../../src/pages/product/supply-chain.jsx" /* webpackChunkName: "component---src-pages-product-supply-chain-jsx" */),
  "component---src-pages-product-traceability-jsx": () => import("./../../../src/pages/product/traceability.jsx" /* webpackChunkName: "component---src-pages-product-traceability-jsx" */),
  "component---src-pages-recruitment-index-jsx": () => import("./../../../src/pages/recruitment/index.jsx" /* webpackChunkName: "component---src-pages-recruitment-index-jsx" */),
  "component---src-pages-solution-list-glue-plowing-jsx": () => import("./../../../src/pages/solution-list/glue-plowing.jsx" /* webpackChunkName: "component---src-pages-solution-list-glue-plowing-jsx" */),
  "component---src-pages-solution-list-index-jsx": () => import("./../../../src/pages/solution-list/index.jsx" /* webpackChunkName: "component---src-pages-solution-list-index-jsx" */),
  "component---src-pages-solution-list-marijuana-jsx": () => import("./../../../src/pages/solution-list/marijuana.jsx" /* webpackChunkName: "component---src-pages-solution-list-marijuana-jsx" */),
  "component---src-pages-solution-list-tea-pattern-cloud-jsx": () => import("./../../../src/pages/solution-list/tea-pattern-cloud.jsx" /* webpackChunkName: "component---src-pages-solution-list-tea-pattern-cloud-jsx" */),
  "component---src-pages-team-intro-jsx": () => import("./../../../src/pages/team-intro.jsx" /* webpackChunkName: "component---src-pages-team-intro-jsx" */),
  "component---src-templates-new-detail-jsx": () => import("./../../../src/templates/newDetail.jsx" /* webpackChunkName: "component---src-templates-new-detail-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-solution-jsx": () => import("./../../../src/templates/solution.jsx" /* webpackChunkName: "component---src-templates-solution-jsx" */)
}

